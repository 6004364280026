import "../styles/Login.css";
import Head from "../utils/Head";
import React, { Component } from "react";
import $ from 'jquery';
import { url, root,  globals } from "../utils/Globals";
import Home from "./Home";
import Swal from "sweetalert2";
const FormLogin = () => {
  class Login extends Component {
    constructor() {
      super();
      this.state = {
        isEventBound: false,
      };
    }
  
    componentDidMount() {
      if (!this.state.isEventBound) {
        document.addEventListener("keypress", this.handleKeyPress);
        this.setState({ isEventBound: true });
      }
      $("#contrasena").attr("autocomplete", "new-password");
      $("#contrasena").attr("autocomplete", "off");
      const botonLogin = document.getElementById('boton_login');
      botonLogin.addEventListener('click', this.handleClick);
    }
  
    componentWillUnmount() {
      document.removeEventListener("keypress", this.handleKeyPress);
  
      const botonLogin = document.getElementById('boton_login');
      botonLogin.removeEventListener('click', this.handleClick);
    }
  
    handleKeyPress = (e) => {
      if (e.which === 13) {
        this.Loguearse();
      }
    };
    handleClick = () => {
      this.Loguearse();
    }
    Loguearse = () => {
      let usuario = $("#usuario").val();
      let constraseña = $("#contrasena").val();
      var respuestaJson;
      const Data = {
        usuario: `${usuario}`,
        contraseña: `${constraseña}`
      };
      fetch(url + "ingresar", {
        method: 'POST',
        body: JSON.stringify(Data),
        headers: {
          "Content-Type": "application/json"
        }
      }).then(response => {
        respuestaJson = response.json();
        return respuestaJson;
      }).then(respuestaJson => {
        if (respuestaJson.token) {
          globals.nombreUsuario = respuestaJson.nombre;
          root.render(
            <React.StrictMode>
              <Home token={respuestaJson.token} usuarioId={respuestaJson.id} perfil={respuestaJson.perfil} usuarioNombre={respuestaJson.nombre} />
            </React.StrictMode>
          );
        } else {
          Swal.fire({
            icon: "error",
            text: respuestaJson.mensaje,
            width: 500,
            height: 200,
          });
        }
        return respuestaJson;
      })
        .catch(err => {
          console.error('Error al enviar la petición POST:', err);
        });
    }
    render() {
      return (
        <div>
          <center>
            <form id="loginForm" style={{ backgroundColor: "#009B7C" }}>
              <Head></Head>
              <br></br>
              <div className="form-floating mb-4 col-md-2">
                <input type="text" id="usuario" className="form-control" />
                <label className="form-label" htmlFor="usuario">
                  Usuario
                </label>
              </div>
              <div className="form-floating mb-4 col-md-2">
                <input type="password" id="contrasena" className="form-control" autoComplete="on" />
                <label className="form-label" htmlFor="contrasena" >
                  Contraseña
                </label>
              </div>
              <div className="form-floating col-md-2">
                <button id="boton_login" type="button" className="btn btn-met float-middle">Ingresar</button>
              </div>
            </form>
          </center>
        </div>
      )
    }
  }
  return (
    <Login />
  )
}
export default FormLogin;
