import "../styles/Login.css";
import Head from "../utils/Head";
import React, { Component } from "react";
import $ from 'jquery';
import { url, root } from "../utils/Globals";
import Home from "./Home";
import Swal from  'sweetalert2';
  class RecuperarContraseña extends Component {
    constructor() {
      super();
      this.state = {
        isEventBound: false,
      };
    }
  
    componentDidMount() {
      if (!this.state.isEventBound) {
        document.addEventListener("keypress", this.handleKeyPress);
        this.setState({ isEventBound: true });
      }
    }
  
    componentWillUnmount() {
      document.removeEventListener("keypress", this.handleKeyPress);
  
      
    }
  
    handleKeyPress = (e) => {
      if (e.which === 13) {
        this.Loguearse();
      }
    };
    handleClick = () => {
      this.Loguearse();
    }
    Loguearse = () => {
      let usuario = $("#usuario").val();
      
      var respuestaJson;
      const Data = {
        usuario: `${usuario}`,
      };
      fetch(url + "ingresar", {
        method: 'POST',
        body: JSON.stringify(Data),
        headers: {
          "Content-Type": "application/json"
        }
      }).then(response => {
        respuestaJson = response.json();
        return respuestaJson;
      }).then(respuestaJson => {
        if (respuestaJson.token) {
          root.render(
            <React.StrictMode>
              <Home token={respuestaJson.token} usuarioId={respuestaJson.id} perfil={respuestaJson.perfil} usuarioNombre={respuestaJson.nombre} />
            </React.StrictMode>
          );
        } else {
          Swal.fire({
            icon: "error",
            text: respuestaJson.mensaje,
            width: 500,
            height: 200,
          });
        }
        return respuestaJson;
      })
        .catch(err => {
          console.error('Error al enviar la petición POST:', err);
        });
    }
    render() {
      return (
        <div>
          <center>
            <form id="loginForm" style={{ backgroundColor: "#009B7C" }}>
              <Head></Head>
              <br></br>
              <h1>Recuperar Contraseña</h1>
              <div className="form-floating mb-4 col-md-2">
                <input type="text" id="usuario" className="form-control" />
                <label className="form-label" htmlFor="usuario">
                  Usuario
                </label>
              </div>
             
              <div className="form-floating col-md-2">
                <button id="botonRecuperar" type="button" className="btn btn-met float-middle">Recuperar</button>
              </div>
            </form>
          </center>
        </div>
      )
    }
  }
  
export default RecuperarContraseña;
